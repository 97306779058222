/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'caret-down-square': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3.626 6.832A.5.5 0 014 6h8a.5.5 0 01.374.832l-4 4.5a.5.5 0 01-.748 0z"/><path pid="1" d="M0 2a2 2 0 012-2h12a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2zm15 0a1 1 0 00-1-1H2a1 1 0 00-1 1v12a1 1 0 001 1h12a1 1 0 001-1z"/>',
    },
});
